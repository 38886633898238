'use client';

import { useEffect, useState, useContext } from 'react';
import { useSearchParams } from 'next/navigation';
import { useRouter, usePathname } from 'i18n/routing';
import { useLocale } from 'next-intl';

import createStyles from '@guestyci/foundation/createStyles';
import Col from '@guestyci/foundation/Layout/Col';
import Dropdown, { Option } from '@guestyci/foundation/Dropdown';
import DropdownLabelInput from '@guestyci/foundation/Dropdown/DropdownLabelInput';

import { WebsiteSettingsContext } from 'context/WebsiteSettingsContext';
import { countryCodeToLabel } from 'constants/languages';

const useStyles = createStyles(({ breakpoints: { create }, spacer, palette }) => ({
  root: {
    [create('xs')]: {
      marginRight: spacer(1),
    },
    [create('md')]: {
      marginRight: spacer(5),
    },
  },
  dropdown: {
    '& > div': {
      minWidth: 70,
      cursor: 'pointer',
    },
    '& [class*="active"]': {
      color: ({ textColor }) => textColor || palette.text.primary,
    },
    '& svg': {
      color: ({ textColor }) => textColor || palette.text.primary,
    },
  },
}));

const SelectLanguageDropdown = () => {
  const locale = useLocale();
  const router = useRouter();
  const searchParams = useSearchParams();
  const searchParamsString = searchParams.toString() || '';

  const pathname = usePathname();
  const { contentConfiguration: { colorTheme: { colors = {} } = {} } = {} } = useContext(WebsiteSettingsContext);
  const { root, dropdown } = useStyles({ textColor: colors?.headerLink });
  const [selectedCountryCode, setSelectedCountryCode] = useState(locale);
  const [languageList, setLanguageList] = useState([]);

  const { enginesData: { languages = [] } = {} } = useContext(WebsiteSettingsContext);

  useEffect(() => {
    if (languages.length) {
      const languageStrings = languages.map(({ language: lang }) => lang);
      setLanguageList(languageStrings);
    }
  }, [languages]);


  if (languages?.length === 1) return null;



  const pathnameWithSearch = `${pathname}?${searchParamsString}`;

  const handleLanguageChange = e => {
    const { value } = e.target;
    setSelectedCountryCode(value);
    router.push(pathnameWithSearch, { locale: value });
  }


  return (
    <Col spacing={2} className={root}>
      <Dropdown
        className={dropdown}
        value={selectedCountryCode}
        onChange={handleLanguageChange}
        input={<DropdownLabelInput />}
        renderSelected={() => countryCodeToLabel(selectedCountryCode)}
        menuWidth={120}
      >
        {languageList.map((lang) => (
          <Option data-qa="currency-dropdown-item" value={lang} key={lang}>
            {countryCodeToLabel(lang)}
          </Option>
        ))}
      </Dropdown>
    </Col>
  );
};

export default SelectLanguageDropdown;
