'use client';

import { useEffect } from 'react';
import moment from 'moment';

import dynamic from 'next/dynamic';

const LocalizationProvider = dynamic(() => import('@guestyci/localize/LocalizationProvider'), { ssr: false });

const loadLocale = async (language) => {
  try {
    // Dynamic import of the specific locale
    await import(`moment/locale/${language}`);
    moment.locale(language);
  } catch (error) {
    console.warn(`Failed to load locale ${language}`, error);
    moment.locale('en');
  }
};


export const ReactLocalizationProvider = ({ children, language }) => {
  useEffect(() => {
    // Ensure moment locale is loaded on client-side only
    if (typeof window !== 'undefined') {
      loadLocale(language);
    }
  }, [language]);
  return (
    <LocalizationProvider onError={e => console.log('localization error:', e)} language={language}>
      {children}
    </LocalizationProvider>
  );
}
