import FacebookIcon from 'assets/icons/facebook.svg';
import TwitterIcon from 'assets/icons/twitter.svg';
import InstagramIcon from 'assets/icons/instagram.svg';
import YoutubeIcon from 'assets/icons/youtube.svg';

const socialIcons = {
  FACEBOOK: FacebookIcon,
  TWITTER: TwitterIcon,
  INSTAGRAM: InstagramIcon,
  YOUTUBE: YoutubeIcon,
};

export default socialIcons;
