'use client';

import { useContext, useEffect, useRef } from 'react';

import { WebsiteSettingsContext } from 'context/WebsiteSettingsContext';
import App from 'app/App';
import useInjectDataDogScript from 'hooks/useInjectDataDogScript';

function injectHTML(html, containerId = 'custom-snippet-container') {

  if (document.getElementById(containerId)) {
    return; // If it exists, do not inject the HTML again
  }
  // Create a container element
  const container = document.createElement('div');
  container.id = containerId;
  container.innerHTML = html;

  // Extract and execute any scripts
  const scripts = container.querySelectorAll('script');
  scripts.forEach(script => {
    const newScript = document.createElement('script');
    if (script.src) {
      // If the script has a src attribute, set it
      newScript.src = script.src;
    } else {
      // Otherwise, copy the inline script content
      newScript.textContent = script.textContent;
    }
    document.body.appendChild(newScript);
    script.remove(); // Remove the original script to avoid duplicate execution
  });

  // Append the remaining content to the body or any other desired location
  document.body.appendChild(container);
}

const Initizalization = () => {
  useInjectDataDogScript();
  const isInjected = useRef(false);
  const { contentConfiguration: { customSnippet } = {}, displayOptions: { customSnippetsOn } = {} } =
    useContext(WebsiteSettingsContext);

  useEffect(() => {
    if (!isInjected.current && customSnippet && customSnippetsOn) {
      isInjected.current = true;
      injectHTML(customSnippet);
    }
  }, [customSnippet, customSnippetsOn])

  return (
    <App />
  );

};

export default Initizalization;
