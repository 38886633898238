'use client';

import { BrowserRouter } from 'react-router-dom';

const BrowerRouterProvider = ({ children }) => {
  return <BrowserRouter>{children}</BrowserRouter>;
}

export default BrowerRouterProvider;

