'use client';

import { useEffect } from 'react';
import { ReactSession } from 'react-client-session';
import uniqid from 'uniqid';

import '@guestyci/foundation/style/styles.css';

import useDio from 'hooks/useDio';
import { useAnalytics } from 'analytics/hooks';
import { CookiePolicyStrategy } from '../components/CookiePolicy/CookiePolicy';

const initSession = () => {
  ReactSession.setStoreType('sessionStorage');
  const userSession = ReactSession.get('user_session');
  if (!userSession) {
    ReactSession.set('user_session', uniqid());
  }

  const userId = localStorage.getItem('user_id');
  if (!userId) {
    localStorage.setItem('user_id', uniqid());
  }
};

const App = () => {
  const { initDio, dioInited } = useDio();
  const { shouldShowCookiePolicy } = useAnalytics();

  useEffect(() => {
    initDio();
    initSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!dioInited) return null;

  return (
    <>
      <CookiePolicyStrategy isVisible={shouldShowCookiePolicy} />
      <div style={{ position: 'fixed', zIndex: -1, opacity: 0 }}>
        <input type="text" />
      </div>
    </>
  );
};

export default App;
