'use client';

import { useMemo, useContext } from 'react';
import { Link } from 'i18n/routing';

import createStyles from '@guestyci/foundation/createStyles';
import TextField from '@guestyci/foundation/TextField';
import t from '@guestyci/localize/t.macro/t.macro';

import { WebsiteSettingsContext } from 'context/WebsiteSettingsContext';
import CurrencySelect from 'components/CurrencySelect';
import SelectLanguageDropdown from 'components/SelectLanguageDropdown';
import { useSearchParams } from 'next/navigation';

const useStyles = createStyles(({ breakpoints: { create } }) => ({
  root: {
    background: ({ background }) => background || '#fff',
    height: 100,
    display: 'flex',
    padding: 20,
    alignItems: 'center',
    flexDirection: 'row',
    [create('xs')]: {
      flexDirection: 'column',
      gap: 20,
    },
    [create('sm')]: {
      flexDirection: 'row',
    },
  },
  navigation: {
    marginLeft: 'auto',
    display: 'flex',
    justifyContent: 'flex-end',
    alignSelf: 'center',
    width: 120,
    [create('xs')]: {
      gap: 10,
      justifyContent: 'center',
      width: '100%',
    },
    [create('sm')]: {
      justifyContent: 'flex-end',
    },
    [create('md')]: {
      gap: 30,
      width: 120,
    },
    '& > a': {
      fontSize: 14,
      color: ({ textColor }) => textColor || '#212121',
    },
  },
  logo: {
    [create('xs')]: {
      marginLeft: 0,
      marginRight: 0,
      height: 30,
    },
    [create('md')]: {
      marginRight: -120,
      height: 60,
      width: 'auto',
    },
    [create('lg')]: {
      marginLeft: 'auto',
    },
    '& > img': {
      width: 'auto',
      height: '100%',
    },
  },
}));

const LogoWithOrWithourExternalWebsiteUrl = ({
  className,
  externalLinkingOn,
  externalWebsiteUrl,
  logoUrl,
  unifiedDomain,
}) => {
  if (unifiedDomain) {
    return (
      <a className={className} href="/" rel="noreferrer">
        <img src={logoUrl} alt="logo" />
      </a>
    );
  }
  if (externalWebsiteUrl && externalLinkingOn) {
    return (
      <a className={className} href={externalWebsiteUrl} target="_blank" rel="noreferrer">
        <img src={logoUrl} alt="logo" />
      </a>
    );
  }
  return (
    <Link
      className={className}
      href='/'
    >
      <img src={logoUrl} alt="logo" />
    </Link>
  );
};

const Header = () => {
  const {
    contentConfiguration: {
      logo: logoImage = '',
      colorTheme: { colors = {} } = {},
      externalWebsiteUrl = null,
      sections,
    } = {},
    displayOptions: { externalLinkingOn = false, shouldShowContactUsForm = false } = {},
    enginesData: { unifiedDomain = false } = {},
  } = useContext(WebsiteSettingsContext);

  const searchParams = useSearchParams();
  const search = searchParams.toString();
  const { root, navigation, logo } = useStyles({
    background: colors?.headerBackground,
    textColor: colors?.headerLink,
  });

  const withContact = useMemo(() => !!sections?.CONTACT?.active, [sections]);
  const withTerms = useMemo(() => !!sections?.TERMS?.active, [sections]);

  return (
    <header className={root}>
      <LogoWithOrWithourExternalWebsiteUrl
        externalWebsiteUrl={externalWebsiteUrl}
        externalLinkingOn={externalLinkingOn}
        logoUrl={logoImage}
        className={logo}
        search={search}
        unifiedDomain={unifiedDomain}
      />
      <div className={navigation}>
        <SelectLanguageDropdown />
        <CurrencySelect />
        {(shouldShowContactUsForm || withContact) && (
          <Link href='/contact'>
            <TextField>{t('Contact')}</TextField>
          </Link>
        )}
        {withTerms && (
          <Link href='/terms'>
            <TextField>{t('Terms')}</TextField>
          </Link>
        )}
      </div>
    </header>
  );
};

export default Header;
