'use client';

import CurrencyContextProvider from 'context/CurrencyContext';

export const ReactCurrencyContextProvider = ({ children }) => {
  return <CurrencyContextProvider>{children}</CurrencyContextProvider>;
}




