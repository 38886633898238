'use client';

import { createContext } from 'react';
import { useSearchValuesForm } from 'hooks/useSearchValues';

const SearchValuesContext = createContext();

export const SearchValuesProvider = ({ children }) => {
  const searchParams = useSearchValuesForm();

  return <SearchValuesContext.Provider value={searchParams}>{children}</SearchValuesContext.Provider>;
};

export { SearchValuesContext };
